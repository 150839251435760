import { CsvUploadResult } from './csv-upload-result';

export class CsvUploadProgress {
  constructor(
    public loaded = new Array<CsvUploadResult>(),
    public archived = new Array<CsvUploadResult>(),
    public created = new Array<CsvUploadResult>(),
    public errors = new Array<CsvUploadResult>(),
    public loadedFinished: boolean = false,
    public archivedFinished: boolean = false,
    public createdFinished: boolean = false,
    public uploadFinished: boolean = false
  ) {}

  addLoaded(result: CsvUploadResult) {
    this.loaded.push(result);
  }

  addArchived(result: CsvUploadResult) {
    this.archived.push(result);
  }

  addCreated(result: CsvUploadResult) {
    this.created.push(result);
  }

  addError(result: CsvUploadResult) {
    this.errors.push(result);
  }
}
