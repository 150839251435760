import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { detect } from 'detect-browser';

import { ConfigManagerService } from '@xpo-ltl/config-manager';

import { ConfigManagerProperties } from '@shared/enums';

@Component({
  selector: 'app-version-banner',
  templateUrl: './version-banner.component.html',
  styleUrls: ['./version-banner.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class VersionBannerComponent implements OnInit {
  browserInfo;

  constructor(private configManagerService: ConfigManagerService) {}

  ngOnInit(): void {
    this.browserInfo = detect();
  }

  get buildVersion(): string {
    return this.configManagerService.getSetting<string>(ConfigManagerProperties.buildVersion);
  }
}
