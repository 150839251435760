import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';

import { FormatValidationService } from '@xpo-ltl/common-services';

export function emailValidator(formatValidator: FormatValidationService): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (control && control.value) {
      return formatValidator.isValidEmails(control.value) ? null : { email: control.value };
    }
    return null;
  };
}

@Directive({
  selector: '[ngModel][appEmailValidator]',
  providers: [{provide: NG_VALIDATORS, useExisting: EmailValidatorDirective, multi: true}]
})
export class EmailValidatorDirective implements Validator {

  constructor(private formatValidator: FormatValidationService) {}

  validate(control: AbstractControl): { [key: string]: any } {
    return emailValidator(this.formatValidator)(control);
  }
}
