import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { MatTabChangeEvent } from '@angular/material/tabs';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ConfigManagerService } from '@xpo-ltl/config-manager';

import { AppHeaderLabels } from '@core/enums/app-header-labels.enum';

import { ConfigManagerProperties } from '@shared/enums';
import { RouterUriComponents } from '@shared/enums/router-uri-components.enum';
import { AppConstantsService } from '@shared/services/app-constants/app-constants.service';
import { InvoiceAuditorService } from '@shared/services/invoice-auditor/invoice-auditor.service';

@Component({
  selector: 'app-toolbar',
  templateUrl: './app-toolbar.component.html',
  styleUrls: ['./app-toolbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppToolbarComponent implements OnInit, OnDestroy {
  appHeaderLabels = AppHeaderLabels;

  isViewInvoiceVisible = false;
  isReleaseInvoicesVisible = false;
  isResendInvoicesVisible = false;
  isRFCManagementVisible = false;
  selectedTab = 0;
  isProduction = false;
  hideReleaseInvoicesTab: boolean;
  hideResendInvoicesTab: boolean;
  hideRFCManagementTab: boolean;

  private unsubscriber = new Subject<void>();

  constructor(
    private router: Router,
    private appConstantsService: AppConstantsService,
    private configManagerService: ConfigManagerService,
    private invoiceAuditorService: InvoiceAuditorService
  ) {}

  ngOnInit() {
    this.invoiceAuditorService.roles$.pipe(takeUntil(this.unsubscriber)).subscribe((roles: string[]) => {
      this.isReleaseInvoicesVisible = this.invoiceAuditorService.isReleaseInvoicesTabVisible(roles);
      this.isViewInvoiceVisible = this.invoiceAuditorService.isViewInvoiceTabVisible(roles);
      this.isRFCManagementVisible = this.invoiceAuditorService.isRFCManagementTabVisible(roles);
      this.isResendInvoicesVisible = this.invoiceAuditorService.isResendInvoicesTabVisible(roles);
      this.isReleaseInvoicesVisible = !this.invoiceAuditorService.isReleaseInvoicesTabHidden(roles);
    });

    this.isProduction = this.configManagerService.getSetting<boolean>(ConfigManagerProperties.production);

    this.router.events.subscribe((result) => {
      if (result instanceof NavigationEnd) {
        this.selectedTab = this.router.getCurrentNavigation().extras.state
          ? this.router.getCurrentNavigation().extras.state.selectedTab
          : 0;
      }
    });

    this.appConstantsService.invoiceReadMode.subscribe((value) => {
      this.hideReleaseInvoicesTab = value;
      this.hideResendInvoicesTab = value;
      this.hideRFCManagementTab = value;
    });
  }

  selectedTabChanged(tabChangeEvent: MatTabChangeEvent) {
    const tabLabel = tabChangeEvent.tab.textLabel.toUpperCase();

    if (tabLabel === 'VIEW INVOICE') {
      this.router.navigate([`/${RouterUriComponents.INVOICING_MAIN_PAGE}`], {
        state: { selectedTab: tabChangeEvent.index },
      });
    }

    if (tabLabel === 'RE-SEND INVOICES') {
      this.router.navigate([`/${RouterUriComponents.INVOICING_RESEND_PAGE}`], {
        state: { selectedTab: tabChangeEvent.index },
      });
    }

    if (tabLabel === 'RELEASE INVOICES') {
      this.router.navigate([`/${RouterUriComponents.RELEASE_INVOICE}`], {
        state: { selectedTab: tabChangeEvent.index },
      });
    }

    if (tabLabel === 'RFC MANAGEMENT') {
      this.router.navigate([`/${RouterUriComponents.RFC_MANAGEMENT}`], {
        state: { selectedTab: tabChangeEvent.index },
      });
    }
  }

  ngOnDestroy() {
    this.unsubscriber.next();
    this.unsubscriber.complete();
    this.unsubscriber = undefined;
  }
}
