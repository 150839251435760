export enum ResendInvoiceFormFields {
  TRANSMISSION_MEDIUM = 'transmissionMedium',
  ATTACHMENT_FORMAT = 'attachmentFormat',
  ATTACHMENT_TYPE = 'attachmentType',
  COMPANY_NAME = 'companyName',
  ADDRESS = 'address',
  ADDRESS2 = 'address2',
  CITY = 'city',
  STATE = 'state',
  ZIP_CODE = 'zipCode',
  COUNTRY = 'country',
  EMAIL_ADDRESS = 'emailAddress',
  EMAIL_ADDRESSES = 'emailAddresses', // To be removed
  SUBJECT = 'subject',
  FAX_NUMBER = 'faxNumber',
  ATTENTION = 'attention',
}
